<otcx-error-modal></otcx-error-modal>
<otcx-layout-disclaimer></otcx-layout-disclaimer>
<otcx-layout-header></otcx-layout-header>
<section role="main">
    <div class="body-content">
        <router-outlet></router-outlet>
    </div>
    <otcx-layout-scroll-to-top class="sticky-bottom-right"></otcx-layout-scroll-to-top>
</section>
<otcx-layout-footer></otcx-layout-footer>
