import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {Faq} from "@kwsoft/otcx-core";

@Component({
    selector: "otcx-search-result-faq",
    templateUrl: "./search-result-faq.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultFaqComponent {
    @Input() faq: Faq;
}
