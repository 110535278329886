<div class="card">
    <div class="card-header-panel">
        <h4>Analysen</h4>
    </div>
    <div class="card-body-plaintext">
        Hier finden Sie eine Übersicht unserer Studien, Nachhaltigkeits- und Branchenanalysen
    </div>
    <div class="card-footer-link">
        <a class="link-icon-text" [routerLink]="['/publications']" data-test="publications-link">
            <span class="library-bekb-icon-font icon-link-intern"></span>
            <span>Analysen anzeigen</span>
        </a>
    </div>
</div>
