import {NgModule} from "@angular/core";
import {ErrorModule, SharedModule} from "@kwsoft/otcx-core";
import {RouterModule} from "@angular/router";
import {HomeTradingDaysComponent} from "./home-trading-days.component";

@NgModule({
    declarations: [
        HomeTradingDaysComponent
    ],
    imports: [
        SharedModule,
        RouterModule,
        ErrorModule
    ],
    exports: [
        HomeTradingDaysComponent
    ]
})
export class TradingDaysModule {
}
