import {Component} from "@angular/core";
import {Observable} from "rxjs";
import {DateTime} from "luxon";

import {CalendarService, DateUtils, ErrorWrapper, ErrorWrapperFactory, Holiday} from "@kwsoft/otcx-core";

@Component({
    selector: "otcx-home-trading-days",
    templateUrl: "./home-trading-days.component.html",
    styleUrls: ["./home-trading-days.component.scss"]
})
export class HomeTradingDaysComponent {
    holidaysLoader: ErrorWrapper<Holiday[]>;
    isExpanded = false;

    fromApiDate = DateUtils.fromApiDate;

    constructor(private readonly calendarService: CalendarService,
                private readonly errorWrapperFactory: ErrorWrapperFactory<Holiday[]>) {
    }

    get showToggleButton(): boolean {
        return !this.holidaysLoader || !this.holidaysLoader.hasError;
    }

    toggleExpand(): void {
        this.isExpanded = !this.isExpanded;
        if (!this.holidaysLoader) {
            this.holidaysLoader = this.errorWrapperFactory.wrap(this.getHolidaysForAYear());
        }
    }

    private getHolidaysForAYear(): Observable<Holiday[]> {
        return this.calendarService.getHolidays(DateUtils.toApiDate(DateTime.local()),
            DateUtils.toApiDate(DateTime.local().plus({years: 1})));
    }

}
