import {Component, Input} from "@angular/core";
import {Security} from "@kwsoft/otcx-core";

@Component({
    selector: "otcx-home-indices-list",
    templateUrl: "./home-index-list.component.html",
    styleUrls: ["./home-index-list.component.scss"]
})
export class HomeIndexListComponent {
    @Input() indices: Security[];
}
