import {Component, OnInit} from "@angular/core";
import {ErrorWrapper, ErrorWrapperFactory, SecurityTradesSummariesList, TradesService} from "@kwsoft/otcx-core";

const PAGE_SIZE = 5;

@Component({
    selector: "otcx-home-securities-active",
    templateUrl: "./home-securities-active.component.html",
    styleUrls: ["./home-securities-active.component.scss"]
})
export class HomeSecuritiesActiveComponent implements OnInit {
    securitiesListLoader: ErrorWrapper<SecurityTradesSummariesList>;

    constructor(
        private readonly tradesService: TradesService,
        private readonly errorWrapperFactory: ErrorWrapperFactory<SecurityTradesSummariesList>) {
    }

    ngOnInit(): void {
        this.securitiesListLoader = this.errorWrapperFactory.wrap(
            this.tradesService.getTradesBySecurityWithinPeriod(PAGE_SIZE));
    }

}
