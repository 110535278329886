<div class="hero">
    <div class="otcx-home">
        <h1>Willkommen auf der Marktübersicht von BEKB | BCBE OTC-X</h1>
        <div class="otcx-home-body-left">
            <otcx-home-trades-latest></otcx-home-trades-latest>
            <otcx-home-news *ngIf="isMobile"></otcx-home-news>
            <otcx-home-index-overview></otcx-home-index-overview>
            <otcx-home-securities-active></otcx-home-securities-active>
            <otcx-home-securities-performers [direction]="'DESC'"></otcx-home-securities-performers>
            <otcx-home-securities-performers [direction]="'ASC'"></otcx-home-securities-performers>
        </div>
        <div class="otcx-home-body-right">
            <otcx-home-news *ngIf="!isMobile"></otcx-home-news>
            <otcx-home-publications></otcx-home-publications>
            <otcx-home-market-reports></otcx-home-market-reports>
            <otcx-home-price-quotation-documents></otcx-home-price-quotation-documents>
        </div>
        <div class="otcx-home-aside">
            <otcx-home-trades-statistics></otcx-home-trades-statistics>
            <otcx-home-newsletter></otcx-home-newsletter>
            <otcx-home-trading-days></otcx-home-trading-days>
            <otcx-home-faq></otcx-home-faq>
        </div>
    </div>
</div>
