<div class="card-body-table">
    <table>
        <tr>
            <th>Index</th>
            <th class="align-right">Kurs</th>
            <th class="align-right">YTD %</th>
        </tr>
        <tr *ngFor="let index of indices">
            <td>
                <a [routerLink]="['/security', index.isin]">{{index.name | index}}</a>
            </td>
            <td class="align-right">{{index.lastPrice | number:'.2-2'}}</td>
            <td class="align-right">{{index.performanceYtd * 100 | signedNumber:".1-1"}}</td>
        </tr>
    </table>
</div>
