import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";

import {StringUtils} from "@kwsoft/otcx-core";

@Injectable({
    providedIn: "root"
})
export class ValorToIsinGuard  {

    private readonly prefix = "CH";
    private readonly prefixChecksum = 13; // C = 12 = 2*1 + 1*2 = 4;  H = 17 = 2*1 + 1*7 = 9

    constructor(private readonly router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const valor = (route.params.valor).split(".")[0];
        const isin = this.convertValorToIsin(valor);
        this.router.navigate(["/security/", isin]);
        return false;
    }

    private convertValorToIsin(valor: string): string {
        const checksum = this.computeChecksum(valor);

        while (valor.length < 9) {
            valor = `0${valor}`;
        }

        return `${this.prefix}${valor}${checksum}`;
    }

    private computeChecksum(valor: string): number {
        // based on calculations explained in section "Prüfziffer": http://www.pruefziffernberechnung.de/I/ISIN.shtml
        const reversed = StringUtils.reverseString(valor);
        let weight = 1;
        let sum = 0;

        for (const char of reversed) {
            // multiply digit by weight 1 or 2
            let intermediary = +char * ((weight % 2) + 1);

            // add sum of resulting digits
            while (intermediary !== 0) {
                sum = sum + (intermediary % 10);
                intermediary = Math.floor(intermediary / 10);
            }

            weight = weight + 1;
        }
        sum = sum + this.prefixChecksum;

        // return the difference of the modulo to 10, result ranging from 0 to 9
        return (10 - (sum % 10)) % 10;
    }
}
