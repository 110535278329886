import {Component, OnDestroy, OnInit} from "@angular/core";
import {PriceQuotationService} from "./core/price-quotation.service";
import {DateTime} from "luxon";

import {Observable, Subject} from "rxjs";
import {FormValidators, Publication} from "@kwsoft/otcx-core";
import {FormControl, Validators} from "@angular/forms";
import {map, shareReplay, takeUntil} from "rxjs/operators";

const DAY_RANGE = 14;
const DATE_FORMAT = "dd.MM.yyyy";

@Component({
    selector: "otcx-home-price-quotation-documents",
    templateUrl: "./home-price-quotation-documents.component.html",
    styleUrls: ["./home-price-quotation-documents.component.scss"]
})
export class HomePriceQuotationDocumentsComponent implements OnInit, OnDestroy {
    priceQuotation$: Observable<Publication>;
    dateControl: FormControl;
    submitDisabled = true;
    displaySearchResult$: Observable<boolean>;

    private readonly destroy$ = new Subject<void>();

    constructor(private readonly priceQuotationService: PriceQuotationService) {
    }

    ngOnInit(): void {
        this.dateControl = new FormControl("", [Validators.required, FormValidators.date()]);

        this.dateControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(status => this.submitDisabled = status !== "VALID");
    }

    submit(): void {
        const date = DateTime.fromFormat(this.dateControl.value, DATE_FORMAT);
        this.priceQuotation$ = this.priceQuotationService.getLatestPriceQuotation(date, date).pipe(shareReplay({refCount: true}));

        this.displaySearchResult$ = this.priceQuotation$.pipe(
            map(() => true)
        );

        this.submitDisabled = true;
    }

    getLatestPriceQuotation(event: Event): void {
        this.downloadLatestPriceQuotation(event, DateTime.local());
    }

    getLastMonthPriceQuotation(event: Event): void {
        this.downloadLatestPriceQuotation(event, DateTime.local().minus({months: 1}).endOf("month"));
    }

    getLastYearPriceQuotation(event: Event): void {
        this.downloadLatestPriceQuotation(event, DateTime.local().minus({years: 1}).endOf("year"));
    }

    private downloadLatestPriceQuotation(event: Event, startDate: DateTime): void {
        event.preventDefault();
        const twoWeeksBefore = startDate.minus({days: DAY_RANGE});
        this.priceQuotationService.downloadLatestPriceQuotation(twoWeeksBefore, startDate).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

}
