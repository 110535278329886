<div class="search-box">
    <input #searchTextInput [formControl]="searchTextControl" [ngClass]="{'has-value': !!searchTextControl.value}"
           class="input" id="search" autocomplete="off"
           type="text" spellcheck="false">
    <label class="floating" for="search">ISIN, Valor, RIC, Titel, Index...</label>

    <div class="search-box-action">
        <button class="btn btn-clear-search" *ngIf="searchTerm" (click)="clearSearch()" data-test="search-delete-button">Löschen</button>
    </div>
</div>

<ng-container *ngIf="searchResults$ | async as searchResults">
    <ng-container *ngIf="showSearchResults">
        <button (click)="toggleSelect(option)" *ngFor="let option of filterOptions" class="badge badge-filter" [ngClass]="{selected: option.selected}"
                [disabled]="isDisabled(option.type, searchResults)">{{option.type}} <span
            class="small">(<otcx-loading-indicator-dots
            *ngIf="isLoading(option.type, searchResults); else numberOfResults"></otcx-loading-indicator-dots><ng-template
            #numberOfResults>{{getNumberOfResults(option.type, searchResults)}}</ng-template>)</span>
        </button>

        <ul>
            <ng-container *ngFor="let result of searchResults">
                <ng-container *ngIf="showResults(result.type)">
                    <li *ngFor="let value of result.values" class="search-result-entry" data-test="search-result-entry">
                        <otcx-search-result-security *ngIf="result.type === searchResultType.SECURITY"
                            [security]="value"></otcx-search-result-security>
                        <otcx-search-result-publication *ngIf="result.type === searchResultType.PUBLICATION"
                            [searchResultPublication]="value"></otcx-search-result-publication>
                        <otcx-search-result-faq *ngIf="result.type === searchResultType.FAQ"
                            [faq]="value"></otcx-search-result-faq>
                        <otcx-search-result-market-report *ngIf="result.type === searchResultType.MARKET_REPORT"
                                                          [marketReport]="value"></otcx-search-result-market-report>
                        <otcx-news-entry *ngIf="result.type === searchResultType.NEWS" [newsEntry]="value"></otcx-news-entry>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </ng-container>
</ng-container>
