import {Component} from "@angular/core";
import {ComponentWithModal, ModalService} from "@kwsoft/otcx-core";
import {SearchService} from "../search/search.service";

@Component({
    selector: "otcx-layout-header",
    templateUrl: "./layout-header.component.html",
    styleUrls: ["./layout-header.component.scss"]
})
export class LayoutHeaderComponent extends ComponentWithModal {
    constructor(private readonly searchService: SearchService,
                modalService: ModalService) {
        super("searchModal", modalService);
    }

    get searchTerm(): string {
        return this.searchService.searchTerm;
    }
}
