import {Directive, ElementRef, Input, OnInit} from "@angular/core";
import {SecuritiesService} from "@kwsoft/otcx-core";
import {catchError, map, take} from "rxjs/operators";

@Directive({
    selector: "[otcxIsinToName]"
})
export class IsinToNameDirective implements OnInit {

    @Input() isin: string;

    constructor(private readonly element: ElementRef<HTMLElement>, private readonly securitiesService: SecuritiesService) {
    }

    ngOnInit(): void {
        this.securitiesService.getSecurity(this.isin)
            .pipe(
                take(1),
                map(security =>
                    this.element.nativeElement.innerText = security.name
                ),
                catchError(() =>
                    this.element.nativeElement.style.display = "none"
                )).subscribe();
    }
}
