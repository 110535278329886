import {AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild} from "@angular/core";
import {FormControl} from "@angular/forms";
import {interval, Observable, of} from "rxjs";
import {debounce, startWith, switchMap} from "rxjs/operators";
import {FilterOption, SearchResult, SearchResultType, SearchService} from "./search.service";
import {ModalService} from "@kwsoft/otcx-core";


export const INPUT_FIELD_DEBOUNCE_TIME = 700;

@Component({
    selector: "otcx-search",
    templateUrl: "./search.component.html",
    styleUrls: ["./search.component.scss"]
})
export class SearchComponent implements OnInit, AfterViewChecked {
    @ViewChild("searchTextInput", {static: true}) searchTextInput: ElementRef;
    @Input() modalId: string;

    searchResultType = SearchResultType;
    searchTextControl: FormControl;
    searchResults$: Observable<SearchResult[]>;
    isOpen: boolean;

    constructor(private readonly searchService: SearchService, private readonly modalService: ModalService) {
    }

    get filterOptions(): FilterOption[] {
        return this.searchService.filterOptions;
    }

    get showSearchResults(): boolean {
        return SearchService.isValidSearchTerm(this.searchTerm);
    }

    get searchTerm(): string {
        return this.searchService.searchTerm;
    }

    ngOnInit(): void {
        this.searchTextControl = new FormControl(this.searchService.searchTerm);

        this.searchResults$ = this.searchTextControl.valueChanges.pipe(
            startWith<string>(this.searchTextControl.value),
            debounce((term: string) => SearchService.isValidSearchTerm(term) ? interval(INPUT_FIELD_DEBOUNCE_TIME) : of(0)),
            switchMap((term: string) =>
                this.searchService.getSearchResults(term)
            )
        );
    }

    ngAfterViewChecked(): void {
        const isModalOpen = this.modalService.isOpen(this.modalId);
        if (isModalOpen && !this.isOpen) {
            this.searchTextInput.nativeElement.focus();
        }
        this.isOpen = isModalOpen;
    }

    isLoading(type: SearchResultType, searchResults: SearchResult[]): boolean {
        return !searchResults.some(searchResult => searchResult.type === type);
    }

    getNumberOfResults(type: SearchResultType, searchResults: SearchResult[]): number {
        const searchResult = searchResults.find(result => result.type === type);
        return searchResult ? searchResult.values.length : 0;
    }

    isDisabled(type: SearchResultType, searchResults: SearchResult[]): boolean {
        return this.isLoading(type, searchResults) || this.getNumberOfResults(type, searchResults) === 0;
    }

    toggleSelect(option: FilterOption): void {
        option.selected = !option.selected;
    }

    showResults(type: SearchResultType): boolean {
        return this.searchService.filterEnabled(type);
    }

    clearSearch(): void {
        this.searchTextControl.setValue("", {emitEvent: false});
        this.searchService.clearSearch();
        this.searchTextInput.nativeElement.focus();
    }

}
