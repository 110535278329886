<div class="disclaimer-container" *ngIf="env.testsystem">
    <div class="disclaimer-wrapper">
        <div class="disclaimer card">
            <h4>Testsystem</h4>
            <div data-test="disclaimer-text">
                Willkommen auf dem OTC-X Testsystem. Die hier gelisteten Daten widerspiegeln nicht den aktuellen
                Stand. Für die angezeigten Informationen besteht kein Anspruch auf Vollständigkeit und Richtigkeit.
            </div>
        </div>
    </div>
</div>
