<div class="card">
    <div class="card-header-panel">
        <h4>Zuletzt auf OTC-X gehandelt</h4>
    </div>

    <otcx-error-or-loading [errorWrapper]="isTradingDayLoader">
        <otcx-error-or-loading *ngIf="!!tradesLoader" [errorWrapper]="tradesLoader">
            <ng-container *ngIf="isTradingDayLoader.data$ | async as isTradingDay; else noTradingDay">
                <ng-container *ngIf="isTradingDay && tradesLoader.data$ | async as trades">
                    <div class="card-body-table" *ngIf="!tradesLoader.hasEmptyData">
                        <table>
                            <tr>
                                <th>Titel</th>
                                <th class="align-right">Kurs</th>
                                <th class="align-right">Volumen</th>
                            </tr>
                            <tr *ngFor="let trade of trades" [ngClass]="{'trades-off-book-tr' : trade.offBook}">
                                <td><a [routerLink]="['/security', trade.isin]">{{trade.name}}</a></td>
                                <td class="align-right">{{trade.price | number:".2-2"}}</td>
                                <td class="align-right">
                                    <small *ngIf="trade.offBook">Off Book</small>
                                    <div>{{trade.volume| number:".0-0"}}</div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="card-body-no-data" *ngIf="tradesLoader.hasEmptyData">
                        Heute gab es noch keine Abschlüsse
                    </div>
                </ng-container>
            </ng-container>

            <ng-template #noTradingDay>
                <div class="card-body-no-data">
                    Heute ist kein Handelstag
                </div>
            </ng-template>
            <div class="card-footer-table-link">
                <a class="link-icon-text" [routerLink]="['trades']" data-test="trades-link">
                    <span class="library-bekb-icon-font icon-link-intern"></span>
                    <span>Abschlüsse der letzten drei Handelstage</span>
                </a>
            </div>
        </otcx-error-or-loading>
    </otcx-error-or-loading>
</div>
