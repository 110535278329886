import {Component, OnInit} from "@angular/core";
import {map, share} from "rxjs/operators";

import {ErrorWrapper, ErrorWrapperFactory, IndexUtils, SecuritiesService, Security, SortBy, SortDirection} from "@kwsoft/otcx-core";

@Component({
    selector: "otcx-home-index-overview",
    templateUrl: "./home-index-overview.component.html",
    styleUrls: ["./home-index-overview.component.scss"]
})
export class HomeIndexOverviewComponent implements OnInit {
    marketIndicesLoader: ErrorWrapper<Security[]>;
    sectorIndicesLoader: ErrorWrapper<Security[]>;
    isExpanded = false;

    constructor(
        private readonly securitiesService: SecuritiesService,
        private readonly errorWrapperFactory: ErrorWrapperFactory<Security[]>) {
    }

    ngOnInit(): void {
        const indices$ = this.securitiesService.findSecuritiesBySector("Index", null, 0, SortBy.NAME, SortDirection.ASC).pipe(
            map(securitiesList => securitiesList.items),
            share()
        );

        this.marketIndicesLoader = this.errorWrapperFactory.wrap(indices$.pipe(
            map(indices => IndexUtils.getOrderedMarketIndices(indices, true))
        ));
        this.sectorIndicesLoader = this.errorWrapperFactory.wrap(indices$.pipe(
            map(indices => indices.reduce(this.sectorIndexReducer, []))
        ));
    }

    toggleExpand(): void {
        this.isExpanded = !this.isExpanded;
    }

    private readonly sectorIndexReducer = (sectorIndices, index) => {
        if (!IndexUtils.MARKET_INDEX_ISINS.includes(index.isin)) {
            index.name = IndexUtils.cutPrefixOtcx(index.name);
            sectorIndices.push(index);
        }
        return sectorIndices;
    }
}
