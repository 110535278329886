import {Component, OnInit} from "@angular/core";
import {map} from "rxjs/operators";

import {
    AnnualAccounts,
    ErrorWrapper,
    ErrorWrapperFactory,
    MarketStats,
    StatsService,
    TradesService
} from "@kwsoft/otcx-core";

export const COLLAPSED_NUMBER_OF_ANNUAL_ACCOUNTS = 3;
export const MAX_NUMBER_OF_ANNUAL_ACCOUNTS = 10;

@Component({
    selector: "otcx-home-trades-statistics",
    templateUrl: "./home-trades-statistics.component.html",
    styleUrls: ["./home-trades-statistics.component.scss"]
})
export class HomeTradesStatisticsComponent implements OnInit {
    marketStatsLoader: ErrorWrapper<MarketStats>;
    tradesStatsLoader: ErrorWrapper<AnnualAccounts[]>;
    isExpanded = false;

    constructor(
        private readonly statsService: StatsService,
        private readonly tradesService: TradesService,
        private readonly errorWrapperFactory: ErrorWrapperFactory<MarketStats | AnnualAccounts[]>) {
    }

    ngOnInit(): void {
        this.marketStatsLoader = this.errorWrapperFactory.wrap(this.statsService.getStats());
        this.tradesStatsLoader = this.errorWrapperFactory.wrap(this.tradesService.getAnnualAccounts().pipe(
            map(accounts => accounts.slice(0, MAX_NUMBER_OF_ANNUAL_ACCOUNTS))
        ));
    }

    visibleStatistics(statistics: AnnualAccounts[]): AnnualAccounts[] {
        return this.isExpanded ? statistics : statistics.slice(0, COLLAPSED_NUMBER_OF_ANNUAL_ACCOUNTS);
    }

    toggleExpand(): void {
        this.isExpanded = !this.isExpanded;
    }
}
