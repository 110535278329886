function kwAppEnvOrDefault(propertyName: string, defaultValue: string): string {
    const anyWindow: any = window;
    return !!anyWindow && !!anyWindow.kwAppEnv && !!anyWindow.kwAppEnv[propertyName] ? anyWindow.kwAppEnv[propertyName] : defaultValue;
}

export const environment = {
    API_BASE_PATH: "/api",
    production: true,
    // env vars read from the environment using the kw-app-env.js file
    // those defaults here are used for production builds (if kw-app-env.js is not substituted)
    debug: kwAppEnvOrDefault("debug", "false") === "true",
    testsystem: kwAppEnvOrDefault("testsystem", "false") === "true",
    keycloakUrl: kwAppEnvOrDefault("keycloakUrl", "https://auth.otc-x.ch/auth/")
};
