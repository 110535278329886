<div class="card">
    <div class="card-header-panel">
        <h4>Marktberichte</h4>
    </div>
    <div class="card-body-plaintext">
        Immer gut informiert. Die aktuellsten Marktberichte im Überblick
    </div>
    <div class="card-footer-link">
        <a class="link-icon-text" [routerLink]="['/marketReports']" data-test="market-reports-link">
            <span class="library-bekb-icon-font icon-link-intern"></span>
            <span>Marktberichte anzeigen</span>
        </a>
    </div>
</div>
