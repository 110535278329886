<div class="card">
    <div class="card-header-panel">
        <h4>Handelszeiten</h4>
    </div>

    <div class="card-body-subtitle">
        <h5>Reguläre Handelstage</h5>
    </div>

    <ul class="card-body-list">
        <li>
            <span data-test="trading-days">Mo – Fr</span> <!-- en dash -->
            <span data-test="trading-days-hours">08.30 – 17.00 Uhr</span> <!-- en dash -->
        </li>
    </ul>

    <div class="card-body-subtitle">
        <h5>Feiertage (handelsfrei)</h5>
    </div>

    <otcx-error-or-loading *ngIf="!!holidaysLoader" [errorWrapper]="holidaysLoader">
        <ul *ngIf="isExpanded" class="card-body-list">
            <li *ngFor="let holiday of holidaysLoader.data$ | async" data-test='holiday'>
                <span data-test="holiday-name">{{holiday.holidayName}}</span>
                <span
                    data-test="holiday-date">{{fromApiDate(holiday.date).toJSDate() | date:"EEEEEE, d. MMMM yyyy"}}</span>
            </li>
        </ul>
    </otcx-error-or-loading>

    <div *ngIf="showToggleButton" class="card-footer-expand" data-test="footer">
        <button (click)="toggleExpand()" class="btn btn-primary btn-icon-text">
            <span [ngClass]="isExpanded ? 'icon-pfeil-oben': 'icon-pfeil-unten'"
                  class="library-bekb-icon-font"></span>{{isExpanded ? "Feiertage ausblenden" : "Anzeigen"}}
        </button>
    </div>
</div>
