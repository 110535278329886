import {Component, OnInit} from "@angular/core";
import {DateUtils, ErrorWrapper, ErrorWrapperFactory, NewsEntriesList, NewsService} from "@kwsoft/otcx-core";
import {DateTime} from "luxon";

@Component({
    selector: "otcx-home-news",
    templateUrl: "./home-news.component.html"
})
export class HomeNewsComponent implements OnInit {
    newsLoader: ErrorWrapper<NewsEntriesList>;

    constructor(
        private readonly newsService: NewsService,
        private readonly errorWrapperFactory: ErrorWrapperFactory<NewsEntriesList>) {
    }

    ngOnInit(): void {
        const twoWeeksAgo = DateUtils.toApiDate(DateTime.local().minus({days: 14}));
        this.newsLoader = this.errorWrapperFactory.wrap(this.newsService.getNewsEntries(twoWeeksAgo));
    }
}
