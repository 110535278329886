import {NgModule} from "@angular/core";
import {CommonModule, registerLocaleData} from "@angular/common";
import localeDeCh from "@angular/common/locales/de-CH";
import {IsinToNameDirective} from "./isin-to-name.directive";


registerLocaleData(localeDeCh);


@NgModule({
    declarations: [
        IsinToNameDirective
    ],
    imports: [CommonModule],
    exports: [
        IsinToNameDirective
    ]
})
export class SharedModule {
}
