<otcx-modal [id]="modalId">
    <otcx-search [modalId]="modalId"></otcx-search>
</otcx-modal>

<header>
    <div class="main-logo-mb">
        <a [routerLink]="['']"><img alt="otcx-logo" src="assets/bekb-logo-otcx-d-mb_v2.svg"></a>
    </div>
    <div class="main-logo-dt">
        <a [routerLink]="['']"><img alt="otcx-logo" src="assets/bekb-logo-otcx-d-dt_v2.svg"></a>
    </div>

    <div class="search-box" *ngIf="!modalOpen">
        <input type="text" autocomplete="off" class="input" id="search" [value]="searchTerm" (focus)="openModal()"
               [ngClass]="{'has-value': !!searchTerm}">
        <label for="search" class="floating">ISIN, Valor, RIC, Titel, Index...</label>

        <div class="search-box-action" data-test="input-search-box-action">
            <span (click)="openModal()" class="search-icon library-bekb-icon-font icon-lupe"></span>
        </div>
    </div>
    <div class="search-button">
        <button class="btn btn-link link-text-icon" (click)="openModal()" data-test="search-button-mobile">Suche<span
            class="search-icon library-bekb-icon-font icon-lupe"></span></button>
    </div>
</header>
