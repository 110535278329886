import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {MarketReport} from "@kwsoft/otcx-core";

@Component({
    selector: "otcx-search-result-market-report",
    templateUrl: "./search-result-market-report.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultMarketReportComponent {
    @Input() marketReport: MarketReport;
}
