import {NgModule} from "@angular/core";
import {ModalModule, OtcxCoreModule, SharedModule, StaticContentModule} from "@kwsoft/otcx-core";
import {RouterModule} from "@angular/router";
import {SearchModule} from "../search/search.module";
import {LayoutScrollToTopComponent} from "./layout-scroll-to-top.component";
import {LayoutFooterComponent} from "./layout-footer.component";
import {LayoutHeaderComponent} from "./layout-header.component";
import {LayoutDisclaimerComponent} from "./layout-disclaimer.component";

@NgModule({
    declarations: [
        LayoutScrollToTopComponent,
        LayoutFooterComponent,
        LayoutHeaderComponent,
        LayoutDisclaimerComponent
    ],
    exports: [
        LayoutScrollToTopComponent,
        LayoutFooterComponent,
        LayoutHeaderComponent,
        LayoutDisclaimerComponent
    ],
    imports: [
        SearchModule,
        StaticContentModule,
        SharedModule,
        RouterModule,
        ModalModule,
        OtcxCoreModule
    ]
})
export class LayoutModule {
}
