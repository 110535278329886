<div [ngClass]="{locked: isLocked}" data-test="news-entry-wrapper">
    <div class="small" data-test="news-entry-pub-date">{{newsEntry.pubDate | date}}</div>
    <a *ngIf="!!newsEntry.link; else needsLogin" [href]="newsEntry.link" class="link-icon-text"
       data-test="news-entry-link" target="_blank">
        <span class="library-bekb-icon-font icon-link-extern white"></span>
        {{newsEntry.headline}}</a>

    <ng-template #needsLogin>
        <div *ngIf="isLocked; else unlocked" class="locked-entry">
            <span class="locked-headline" data-test="news-entry-locked-headline">{{newsEntry.headline}}</span>
            <div class="unlock-link">
                <a [routerLink]="['news', timestamp]" data-test="news-entry-link">AWP freischalten</a>
            </div>
        </div>
        <ng-template #unlocked>
            <a [routerLink]="['news', timestamp]" data-test="news-entry-link">{{newsEntry.headline}}</a>
        </ng-template>
    </ng-template>
</div>
