import {NgModule} from "@angular/core";

import {NewsRoutingModule} from "./news-routing.module";
import {NewsComponent} from "./news.component";
import {NewsListComponent} from "./news-list.component";
import {ErrorModule, SharedModule} from "@kwsoft/otcx-core";
import {RouterModule} from "@angular/router";
import {NewsEntryComponent} from "./news-entry.component";
import {HttpClientModule} from "@angular/common/http";
import {SharedModule as OtcxSharedModule} from "../shared/shared.module";

@NgModule({
    declarations: [NewsComponent, NewsListComponent, NewsEntryComponent],
    exports: [
        NewsListComponent,
        NewsEntryComponent
    ],
    imports: [
        SharedModule,
        OtcxSharedModule,
        NewsRoutingModule,
        RouterModule,
        HttpClientModule,
        ErrorModule
    ]
})
export class NewsModule {
}
