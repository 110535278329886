import {Component, Input} from "@angular/core";
import {ArrayUtils, DateUtils, NewsEntry} from "@kwsoft/otcx-core";

@Component({
    selector: "otcx-news-list",
    templateUrl: "./news-list.component.html",
    styleUrls: ["./news-list.component.scss"]
})
export class NewsListComponent {
    @Input() newsList: NewsEntry[];
    @Input() collapsedThreshold: number;
    @Input() showAllLabel: string;

    isExpanded = false;

    fromApiDate = DateUtils.fromApiDate;

    get hasNews(): boolean {
        return !ArrayUtils.isEmpty(this.newsList);
    }

    get isExpandable(): boolean {
        return this.newsList.length > this.collapsedThreshold;
    }

    get visibleNews(): NewsEntry[] {
        return this.isExpanded ? this.newsList : this.newsList.slice(0, this.collapsedThreshold);
    }

    toggleExpand(): void {
        this.isExpanded = !this.isExpanded;
    }
}
