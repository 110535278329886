<div class="card">
    <div class="card-header-panel">
        <h4>Statistik</h4>
    </div>

    <otcx-error-or-loading [errorWrapper]="marketStatsLoader">
        <otcx-error-or-loading [errorWrapper]="tradesStatsLoader">
            <ng-container *ngIf="marketStatsLoader.data$ | async as marketStats">
                <div class="card-body-kpi">
                    <div>Anzahl Titel auf OTC-X</div>
                    <a [routerLink]="['/securities']">Alle anzeigen</a>
                    <h2 class="light">{{marketStats.titleCount}}</h2>
                </div>
                <div class="card-body-kpi">
                    <div>Markt-Kapitalisierung (Mia CHF)</div>
                    <h2 class="light">{{marketStats.marketCap | billion}}</h2>
                </div>
            </ng-container>

            <div class="card-body-subtitle">
                <h5>Umsatzstatistik</h5>
            </div>

            <div class="card-body-table">
                <ng-container *ngIf="tradesStatsLoader.data$ | async as tradesLoaderData">
                    <table *ngIf="visibleStatistics(tradesLoaderData) as tradesStats">
                        <tr>
                            <th>Jahr</th>
                            <th class="align-right">Abschlüsse</th>
                            <th class="align-right">Volumen</th>
                            <th class="align-right">Umsatz</th>
                        </tr>
                        <tr *ngFor="let tradesStat of tradesStats">
                            <td>{{tradesStat.year}}</td>
                            <td class="align-right">{{tradesStat.tradeSize | number: '.0-0'}}</td>
                            <td class="align-right">{{tradesStat.volume| number: '.0-0'}}</td>
                            <td class="align-right">{{tradesStat.turnover| million}}</td>
                        </tr>
                    </table>
                </ng-container>
            </div>

            <div class="card-footer-expand" data-test="footer">
                <button (click)="toggleExpand()" class="btn btn-primary btn-icon-text">
                    <span [ngClass]="isExpanded ? 'icon-pfeil-oben': 'icon-pfeil-unten'"
                          class="library-bekb-icon-font"></span>{{isExpanded ? "Weniger anzeigen" : "10 Jahre anzeigen"}}
                </button>
            </div>
        </otcx-error-or-loading>
    </otcx-error-or-loading>
</div>
