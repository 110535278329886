import {NgModule} from "@angular/core";
import {ErrorModule, SharedModule, TabsModule} from "@kwsoft/otcx-core";
import {HomeTradesStatisticsComponent} from "./home-trades-statistics.component";
import {HomeTradesAllComponent} from "./home-trades-all.component";
import {HomeTradesLatestComponent} from "./home-trades-latest.component";
import {RouterModule} from "@angular/router";


@NgModule({
    declarations: [
        HomeTradesStatisticsComponent,
        HomeTradesAllComponent,
        HomeTradesLatestComponent
    ],
    exports: [
        HomeTradesStatisticsComponent,
        HomeTradesAllComponent,
        HomeTradesLatestComponent
    ],
    imports: [
        SharedModule,
        RouterModule,
        ErrorModule,
        TabsModule
    ]
})
export class TradesModule {
}
