<div class="trades-all">
    <h1>BEKB | BCBE OTC-X Abschlüsse</h1>
    <otcx-back-to-home></otcx-back-to-home>
    <div class="trades-table">
        <h5 class="page-title">Abschlüsse der letzten drei Handelstage</h5>
        <otcx-error-or-loading [errorWrapper]="tradesLoader">
            <otcx-tabs *ngIf="tradesLoader.data$ | async as dailyTrades">
                <otcx-tab
                    *ngFor="let dailyTrade of dailyTrades; let isInitiallyActive = first"
                    title="{{dailyTrade.date.toJSDate() | weekdayDate}}"
                    [active]="isInitiallyActive">
                    <otcx-error-or-loading *ngIf="dailyTrade.tradesLoader" [errorWrapper]="dailyTrade.tradesLoader">
                        <ng-container *ngIf="dailyTrade.tradesLoader.data$ | async as trades">
                            <ng-container
                                *ngIf="!dailyTrade.tradesLoader.hasEmptyData; else noEntries">
                                <table>
                                    <tr>
                                        <th>Titel</th>
                                        <th class="align-right">Zeit</th>
                                        <th class="align-right">Kurs</th>
                                        <th class="align-right">Volumen</th>
                                    </tr>
                                    <tr *ngFor="let trade of trades" [ngClass]="{'trades-off-book-tr' : trade.offBook}">
                                        <td><a [routerLink]="['/security/', trade.isin]">{{trade.name}}</a></td>
                                        <td class="align-right">{{fromApiDate(trade.datetime).toJSDate() | date: "HH:mm"}}</td>
                                        <td class="align-right">{{trade.price | number: '.2-2'}}</td>
                                        <td class="align-right">
                                            <small *ngIf="trade.offBook">Off Book</small>
                                            <div>{{trade.volume | number: '.0-0'}}</div>
                                        </td>
                                    </tr>
                                </table>
                            </ng-container>
                            <ng-template #noEntries>
                                <div *ngIf="isToday(dailyTrade.date)" class="no-entries" data-test="empty-table-message">Heute gab es noch keine Abschlüsse</div>
                            </ng-template>
                        </ng-container>
                    </otcx-error-or-loading>
                </otcx-tab>
            </otcx-tabs>
        </otcx-error-or-loading>
    </div>
</div>
