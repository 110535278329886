import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {SearchResultPublication} from "@kwsoft/otcx-core";

@Component({
    selector: "otcx-search-result-publication",
    templateUrl: "./search-result-publication.component.html",
    styleUrls: ["./search-result-publication.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchResultPublicationComponent {
    @Input() searchResultPublication: SearchResultPublication;
}
