import {Component, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {Address, Asset, Contact, Link, Newsletter, StaticContentService} from "@kwsoft/otcx-core";

@Component({
    selector: "otcx-layout-footer",
    templateUrl: "./layout-footer.component.html",
    styleUrls: ["./layout-footer.component.scss"]
})
export class LayoutFooterComponent implements OnInit {
    address$: Observable<Address>;
    tradingRegulations$: Observable<Asset>;
    disclaimer$: Observable<Link>;
    dataPrivacy$: Observable<Link>;
    contact$: Observable<Contact>;
    newsletter$: Observable<Newsletter>;

    constructor(private readonly staticContentService: StaticContentService) {
    }

    ngOnInit(): void {
        this.address$ = this.staticContentService.getAddress();
        this.tradingRegulations$ = this.staticContentService.getTradingRegulations();
        this.disclaimer$ = this.staticContentService.getDisclaimer();
        this.dataPrivacy$ = this.staticContentService.getDataPrivacy();
        this.contact$ = this.staticContentService.getContact();
        this.newsletter$ = this.staticContentService.getNewsletter();
    }
}
