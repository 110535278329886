import {Component, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {Newsletter, StaticContentService} from "@kwsoft/otcx-core";

@Component({
    selector: "otcx-home-newsletter",
    templateUrl: "./home-newsletter.component.html"
})
export class HomeNewsletterComponent implements OnInit {
    newsletter$: Observable<Newsletter>;

    constructor(private readonly staticContentService: StaticContentService) {
    }

    ngOnInit(): void {
        this.newsletter$ = this.staticContentService.getNewsletter();
    }

}
