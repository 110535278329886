<div class="card">
    <div class="card-header-panel">
        <h4>{{title}}</h4>
        <span>Performance YTD</span>
    </div>

    <otcx-error-or-loading [errorWrapper]="securitiesListLoader">
        <div class="card-body-table">
            <table *ngIf="securitiesListLoader.data$ | async as securitiesList">
                <tr>
                    <th>Titel</th>
                    <th class="align-right">%</th>
                </tr>
                <tr *ngFor="let security of securitiesList">
                    <td>
                        <a [routerLink]="['/security', security.isin]">{{security.name}}</a>
                    </td>
                    <td class="align-right">{{security.performanceYtd*100 | signedNumber:".1-1"}}</td>
                </tr>
            </table>
        </div>
    </otcx-error-or-loading>
</div>
