import {Component} from "@angular/core";
import {environment} from "../../environments/environment";

@Component({
    selector: "otcx-layout-disclaimer",
    templateUrl: "./layout-disclaimer.component.html",
    styleUrls: ["./layout-disclaimer.component.scss"]
})
export class LayoutDisclaimerComponent {
    readonly env = environment;
}
