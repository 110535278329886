import {NgModule} from "@angular/core";
import {ErrorModule, SharedModule} from "@kwsoft/otcx-core";
import {HomeNewsComponent} from "./home-news.component";
import {NewsModule as NewsFeatureModule} from "../../news/news.module";


@NgModule({
    declarations: [HomeNewsComponent],
    imports: [
        SharedModule,
        NewsFeatureModule,
        ErrorModule
    ],
    exports: [
        NewsFeatureModule,
        HomeNewsComponent
    ]
})
export class NewsModule {
}
