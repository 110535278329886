<div class="card">
    <div class="card-header-panel">
        <h4>News</h4>
    </div>

    <otcx-error-or-loading [errorWrapper]="newsLoader">
        <otcx-news-list
            *ngIf="newsLoader.data$ | async as news" [newsList]="news.items"
            [collapsedThreshold]="5"
            showAllLabel="Letzte 2 Wochen anzeigen">
        </otcx-news-list>
    </otcx-error-or-loading>
</div>
