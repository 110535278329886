import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {DateUtils} from "@kwsoft/otcx-core";

@Component({
    selector: "otcx-news-entry",
    templateUrl: "./news-entry.component.html",
    styleUrls: ["./news-entry.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsEntryComponent {
    @Input() newsEntry;

    get isLocked(): boolean {
        return this.newsEntry.needsLogin && !this.newsEntry.newsText;
    }

    get timestamp(): number {
        return DateUtils.fromApiDate(this.newsEntry.pubDate).toSeconds();
    }
}
