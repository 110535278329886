<div class="card">
    <div class="card-header-panel">
        <h4>Aktivste 5 Titel</h4>
        <span>Über die letzten 30 Tage</span>
    </div>

    <otcx-error-or-loading [errorWrapper]="securitiesListLoader">
        <div class="card-body-table">
            <table *ngIf="securitiesListLoader.data$ | async as securitiesList">
                <tr>
                    <th>Titel</th>
                    <th class="align-right">Umsatz</th>
                    <th class="align-right">Abschlüsse</th>
                </tr>
                <tr *ngFor="let item of securitiesList.items">
                    <td><a [routerLink]="['/security/', item.isin]">{{item.name}}</a></td>
                    <td class="align-right">{{item.turnover | number:'.0-0'}}</td>
                    <td class="align-right">{{item.tradeSize | number:'.0-0'}}</td>
                </tr>
            </table>
        </div>
    </otcx-error-or-loading>
</div>
