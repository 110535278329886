import {NgModule} from "@angular/core";
import {SearchComponent} from "./search.component";
import {SharedModule} from "@kwsoft/otcx-core";
import {SearchResultSecurityComponent} from "./search-result-security.component";
import {RouterModule} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {SearchResultPublicationComponent} from "./search-result-publication.component";
import {SearchResultFaqComponent} from "./search-result-faq.component";
import {SearchResultMarketReportComponent} from "./search-result-market-report.component";
import {NewsModule} from "../news/news.module";
import {SharedModule as OtcxSharedModule} from "../shared/shared.module";

@NgModule({
    declarations: [
        SearchComponent,
        SearchResultSecurityComponent,
        SearchResultPublicationComponent,
        SearchResultFaqComponent,
        SearchResultMarketReportComponent
    ],
    exports: [SearchComponent],
    imports: [
        SharedModule,
        OtcxSharedModule,
        RouterModule,
        ReactiveFormsModule,
        NewsModule
    ]
})
export class SearchModule {
}
