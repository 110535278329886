<div class="card">
    <div class="card-header-panel">
        <h4>Indexübersicht OTC-X</h4>
    </div>

    <otcx-error-or-loading [errorWrapper]="marketIndicesLoader">
        <otcx-error-or-loading [errorWrapper]="sectorIndicesLoader">
            <div class="card-body-subtitle">
                <h5>Hauptindizes</h5>
            </div>

            <otcx-home-indices-list [indices]="marketIndicesLoader.data$ | async"
                                    data-test="market-indices"></otcx-home-indices-list>

            <div class="card-body-subtitle" [ngClass]="{'border-bottom': !isExpanded}">
                <h5>Branchenindizes</h5>
            </div>

            <ng-container *ngIf="sectorIndicesLoader.data$ | async as sectorIndices">
                <otcx-home-indices-list *ngIf="isExpanded" [indices]="sectorIndices"
                                        data-test="sector-indices"></otcx-home-indices-list>
            </ng-container>

            <div class="card-footer-expand" data-test="toggle-sector-indices">
                <button class="btn btn-primary btn-icon-text" (click)="toggleExpand()">
            <span class="library-bekb-icon-font"
                  [ngClass]="isExpanded ? 'icon-pfeil-oben': 'icon-pfeil-unten'">
            </span>{{isExpanded ? "Branchenindizes ausblenden" : "Anzeigen"}}
                </button>
            </div>
        </otcx-error-or-loading>
    </otcx-error-or-loading>
</div>
