import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {HomeComponent} from "./home.component";
import {HomeTradesAllComponent} from "./trades/home-trades-all.component";
import {AnalyticsRouteDataService} from "@kwsoft/otcx-core";

const routes: Routes = [
    {
        path: "",
        component: HomeComponent,
        data: AnalyticsRouteDataService.createRouteData({
            pageId: "d9584911-ab44-490d-b0a7-796168298508",
            pageType: "startseite"
        })
    },
    {
        path: "trades",
        component: HomeTradesAllComponent,
        data: AnalyticsRouteDataService.createRouteData({
            pageId: "c97d0ad6-7830-4952-96b4-d9382e86fd20",
            pageType: "uebersicht"
        })
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HomeRoutingModule {
}
