import {Component, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {DateTime} from "luxon";

import {
    ArrayUtils,
    CalendarService,
    DateUtils,
    ErrorWrapper,
    ErrorWrapperFactory,
    Holiday,
    Trade,
    TradesService
} from "@kwsoft/otcx-core";

export interface TradesForDate {
    date: DateTime;
    tradesLoader: ErrorWrapper<Trade[]>;
}

@Component({
    selector: "otcx-home-trades-all",
    templateUrl: "./home-trades-all.component.html",
    styleUrls: ["./home-trades-all.component.scss"]
})
export class HomeTradesAllComponent implements OnInit {
    fromApiDate = DateUtils.fromApiDate;
    tradesLoader: ErrorWrapper<TradesForDate[]>;

    constructor(
        private readonly tradesService: TradesService,
        private readonly calendarService: CalendarService,
        private readonly errorWrapperFactory: ErrorWrapperFactory<TradesForDate[] | Trade[]>) {
    }

    ngOnInit(): void {
        this.tradesLoader = this.errorWrapperFactory.wrap(this.getLastThreeTradingDays().pipe(
            map(tradingDays => this.convertToTradesForDates(tradingDays))
        ));
    }

    private getLastThreeTradingDays(): Observable<DateTime[]> {
        return this.getHolidaysOfLastTwoWeeks().pipe(
            // only return the first 3 days which are not on a weekend and not a holiday.
            // Assumes that there will always be at least 3 trading days
            // within the last 2 weeks - but if not, then hell froze over...
            map(holidays => {
                const tradingDays: DateTime[] = [];
                let potentialTradingDay = DateTime.local();
                while (tradingDays.length < 3) {
                    if (this.isTradingDay(potentialTradingDay, holidays)) {
                        tradingDays.push(potentialTradingDay.startOf("day"));
                    }
                    potentialTradingDay = potentialTradingDay.minus({days: 1});
                }
                return tradingDays;
            })
        );
    }

    private isTradingDay(date: DateTime, holidays: Holiday[]): boolean {
        return date.weekday < 6 && !holidays.some(holiday => holiday.date === DateUtils.toApiDate(date));
    }

    private convertToTradesForDates(tradingDays: DateTime[]): TradesForDate[] {
        return tradingDays.map(tradingDay => this.convertToTradesForDate(tradingDay));
    }

    private convertToTradesForDate(tradingDay: DateTime): TradesForDate {
        return {
            date: tradingDay,
            tradesLoader: this.errorWrapperFactory.wrap(this.getTradesForTradingDay(tradingDay))
        };
    }

    private getTradesForTradingDay(tradingDay: DateTime): Observable<Trade[]> {
        return this.tradesService.findTradesByDate(DateUtils.toApiDate(tradingDay)).pipe(
            map(tradesList => tradesList.items)
        );
    }

    private getHolidaysOfLastTwoWeeks(): Observable<Holiday[]> {
        const today = DateTime.local();
        const twoWeeksAgo = today.minus({days: 14});
        return this.calendarService.getHolidays(DateUtils.toApiDate(twoWeeksAgo), DateUtils.toApiDate(today))
            .pipe(shareReplay({refCount: true}));
    }

    isEmpty(trades: Trade[]): boolean {
        return ArrayUtils.isEmpty(trades);
    }

    isToday(date: DateTime): boolean {
        return date.startOf("day").equals(DateTime.local().startOf("day"));
    }

}
