import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";

import {ErrorModule, SharedModule} from "@kwsoft/otcx-core";
import {HomeIndexOverviewComponent} from "./home-index-overview.component";
import {HomeIndexListComponent} from "./home-index-list.component";


@NgModule({
    declarations: [
        HomeIndexOverviewComponent,
        HomeIndexListComponent
    ],
    imports: [
        SharedModule,
        RouterModule,
        ErrorModule
    ],
    exports: [
        HomeIndexOverviewComponent
    ]
})
export class IndexModule {
}
