import {inject, NgModule} from "@angular/core";
import {ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes} from "@angular/router";
import {NewsComponent} from "./news.component";
import {AnalyticsRouteDataService, AuthenticatedGuard} from "@kwsoft/otcx-core";


export const routes: Routes = [{
    path: "news/:timestamp",
    component: NewsComponent,
    canActivate: [(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthenticatedGuard).canActivate(route, state)],
    data: AnalyticsRouteDataService.createRouteData({pageId: "babff6ad-4ebf-4ed1-aecc-5c440eadf59e"})
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class NewsRoutingModule {
}
