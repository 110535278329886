import {Component, Input, OnInit} from "@angular/core";

import {ErrorWrapper, ErrorWrapperFactory, SecuritiesService, Security, SortBy, SortDirection} from "@kwsoft/otcx-core";
import {map} from "rxjs/operators";

@Component({
    selector: "otcx-home-securities-performers",
    templateUrl: "./home-securities-performers.component.html"
})
export class HomeSecuritiesPerformersComponent implements OnInit {

    @Input() direction: SortDirection;
    securitiesListLoader: ErrorWrapper<Security[]>;

    constructor(
        private readonly securitiesService: SecuritiesService,
        private readonly errorWrapperFactory: ErrorWrapperFactory<Security[]>) {
    }

    ngOnInit(): void {
        this.securitiesListLoader = this.errorWrapperFactory.wrap(this.securitiesService.getSecurities(SortBy.PERFORMANCEYTD, this.direction, 5)
            .pipe(
                map(response => response.items)
            ));
    }

    get title(): string {
        return this.direction === SortDirection.ASC ? "Top 5 Verlierer" : "Top 5 Gewinner";
    }
}
