import {Injectable} from "@angular/core";
import {BehaviorSubject, merge, Observable, of} from "rxjs";
import {map, scan, shareReplay, tap} from "rxjs/operators";

import {
    ArrayUtils,
    Faq,
    MarketReport,
    NewsEntry,
    NewsService,
    SearchResultPublication,
    SecuritiesService,
    Security,
    StaticContentService
} from "@kwsoft/otcx-core";

export enum SearchResultType {
    SECURITY = "Titel/Index",
    NEWS = "News",
    FAQ = "FAQ",
    MARKET_REPORT = "Marktberichte",
    PUBLICATION = "Dokumente"
}

type SearchResultValue = Security | Faq | MarketReport | SearchResultPublication | NewsEntry;

export interface SearchResult {
    type: SearchResultType;
    values: SearchResultValue[];
}

export interface FilterOption {
    type: SearchResultType;
    selected: boolean;
}

@Injectable({
    providedIn: "root"
})
export class SearchService {
    filterOptions: FilterOption[];
    private lastSearchResults$: Observable<SearchResult[]> = of([]);
    private readonly currentSearchTerm = new BehaviorSubject<string>("");

    constructor(private readonly securitiesService: SecuritiesService,
                private readonly staticContentService: StaticContentService,
                private readonly newsService: NewsService) {
        this.initFilterOptions();
    }

    get searchTerm(): string {
        return this.currentSearchTerm.value;
    }

    static isValidSearchTerm(term: string): boolean {
        return !!term && term.trim().length > 2;
    }

    clearSearch(): void {
        this.getSearchResults("");
        this.filterOptions.forEach(option => option.selected = false);
    }

    getSearchResults(term: string): Observable<SearchResult[]> {
        if (this.searchTerm !== term) {
            this.currentSearchTerm.next(term);
            this.updateSearchResults(term);
        }
        return this.lastSearchResults$;
    }

    filterEnabled(type: SearchResultType): boolean {
        return !this.filterOptions.some(option => option.selected) || this.filterOptions.find(option => option.type === type).selected;
    }

    private updateSearchResults(term: string): void {
        this.lastSearchResults$ = SearchService.isValidSearchTerm(term) ?
            merge(of([]), this.searchSecurities(term), this.searchPublications(term), this.searchNews(term), this.searchFaqs(term), this.searchMarketReports(term)).pipe(
                scan((allResults, newResults) => allResults.concat(newResults), []),
                shareReplay({refCount: true})
            ) : of([]);
    }

    private searchSecurities(term: string): Observable<SearchResult[]> {
        return this.handleSearchResult(this.securitiesService.findSecuritiesByText(term).pipe(map(list => list.items)), SearchResultType.SECURITY);
    }

    private searchNews(term: string): Observable<SearchResult[]> {
        return this.handleSearchResult(this.newsService.findNewsEntriesByText(term).pipe(map(list => list.items)), SearchResultType.NEWS);
    }

    private searchPublications(term: string): Observable<SearchResult[]> {
        return this.handleSearchResult(this.staticContentService.getPublicationsByText(term), SearchResultType.PUBLICATION);
    }

    private searchFaqs(term: string): Observable<SearchResult[]> {
        return this.handleSearchResult(this.staticContentService.getFaqsByText(term), SearchResultType.FAQ);
    }

    private searchMarketReports(term: string): Observable<SearchResult[]> {
        return this.handleSearchResult(this.staticContentService.getMarketReportsByText(term), SearchResultType.MARKET_REPORT);
    }

    private handleSearchResult(data$: Observable<SearchResultValue[]>, type: SearchResultType): Observable<SearchResult[]> {
        return data$.pipe(
            tap(data => this.resetFilterOptionIfEmpty(data, type)),
            map(data => this.toSearchResults(data, type))
        );
    }

    private toSearchResults(items: SearchResultValue[], type: SearchResultType): SearchResult[] {
        return [{
            type,
            values: items
        }];
    }

    private initFilterOptions(): void {
        this.filterOptions = Object.keys(SearchResultType)
            .map(key => ({type: SearchResultType[key], selected: false}));
    }

    private resetFilterOptionIfEmpty(result: SearchResultValue[], type: SearchResultType): void {
        if (ArrayUtils.isEmpty(result)) {
            this.filterOptions.find(option => option.type === type).selected = false;
        }
    }
}
