<div class="otcx-news">
    <div class="header">
        <div *ngIf="isin && !securityNameLoader.hasEmptyData else backToHome" class="back-link">
            <a [routerLink]="['/security', isin]" class="link-icon-text" data-test="back-to-home">
                <span class="library-bekb-icon-font icon-link-intern-zurueck"></span>
                <span>Zu {{securityNameLoader.hasError ? isin : (securityNameLoader.data$ | async)}}</span>
            </a>
        </div>
        <ng-template #backToHome>
            <otcx-back-to-home></otcx-back-to-home>
        </ng-template>
        <h1>BEKB | BCBE OTC-X AWP News</h1>
        <div class="page-title">AWP News</div>
    </div>
    <div class="news-content">
        <otcx-error-or-loading [errorWrapper]="newsLoader">
            <ng-container *ngIf="newsLoader.data$ | async as news">
                <div class="small news-content-date" data-test="news-date">{{fromApiDate(news.pubDate).toJSDate() | date}}</div>
                <h2 class="news-content-headline" data-test="news-headline">{{news.headline}}</h2>
                <div *ngFor="let isin of news.isins">
                    <a class="news-content-isin-link" otcxIsinToName [isin]="isin"
                       [routerLink]="['/security', isin]"></a>
                </div>
                <div class="news-content-text">
                    <otcx-wysiwyg-content data-test="news-text" [content]="news.newsText"></otcx-wysiwyg-content>
                </div>
            </ng-container>
        </otcx-error-or-loading>
    </div>
</div>
