import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {IndexUtils, Security} from "@kwsoft/otcx-core";

@Component({
    selector: "otcx-search-result-security",
    templateUrl: "./search-result-security.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./search-result-security.component.scss"]
})
export class SearchResultSecurityComponent {
    @Input() security: Security;
    isIndex = IndexUtils.isIndex;
}
