import {Component, HostListener, Inject, OnInit, ViewEncapsulation} from "@angular/core";
import {WINDOW} from "@kwsoft/otcx-core";

const TABLET_BREAKPOINT = 768;

@Component({
    selector: "otcx-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

    isMobile: boolean;

    constructor(@Inject(WINDOW) private readonly window: Window) {
    }

    ngOnInit(): void {
        this.isMobile = this.isMobileBreakpoint(this.window);
    }

    @HostListener("window:resize", ["$event"])
    onResize(event): void {
        this.isMobile = this.isMobileBreakpoint(event.target);
    }

    private isMobileBreakpoint(window: Window): boolean {
        return window.innerWidth < TABLET_BREAKPOINT;
    }
}
