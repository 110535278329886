import {inject, NgModule} from "@angular/core";
import {ActivatedRouteSnapshot, RouterModule, Routes} from "@angular/router";
import {RedirectOnlyComponent} from "./redirect-only.component";
import {ValorToIsinGuard} from "./valor-to-isin.guard";
import {AnalyticsRouteDataService} from "@kwsoft/otcx-core";

export const routes: Routes = [
    {
        path: "marketReports",
        loadChildren: () => import("./market-report/market-report.module").then(mod => mod.MarketReportModule)
    },
    {
        path: "security",
        loadChildren: () => import("./security/security.module").then(mod => mod.SecurityModule)
    },
    {
        path: "markt/instrument/valor/:valor",
        component: RedirectOnlyComponent,
        canActivate: [(route: ActivatedRouteSnapshot) => inject(ValorToIsinGuard).canActivate(route)]
    },
    {
        path: "about",
        loadChildren: () => import("./about/about.module").then(mod => mod.AboutModule)
    },
    {
        path: "publications",
        loadChildren: () => import("./publications/publications.module").then(mod => mod.PublicationsModule)
    },
    {
        path: "securities",
        loadChildren: () => import("./securities/securities.module").then(mod => mod.SecuritiesModule)
    },
    {
        path: "redirect",
        component: RedirectOnlyComponent,
        data: AnalyticsRouteDataService.createRouteData({
            pageId: "672be580-40b9-41da-bd54-517d8a46bb07",
            pageTitleOverride: "Redirect nach AWP-News Login"
        })
    },
    {
        path: "**",
        redirectTo: "/",
        pathMatch: "full"
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    // scroll position restoration and anchor scrolling handled manually in ScrollRestorationService
    scrollPositionRestoration: "disabled",
    anchorScrolling: "disabled",
    // this is required to ensure that anchor changes trigger navigation events
    onSameUrlNavigation: "reload"
})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
