import {Component, OnInit} from "@angular/core";
import {
    ArrayUtils,
    CalendarService,
    DateUtils,
    ErrorWrapper,
    ErrorWrapperFactory,
    Trade,
    TradesService
} from "@kwsoft/otcx-core";
import {of} from "rxjs";
import {map, switchMap} from "rxjs/operators";
import {DateTime} from "luxon";

const PAGE_SIZE = 5;

@Component({
    selector: "otcx-home-trades-latest",
    templateUrl: "./home-trades-latest.component.html",
    styleUrls: ["./home-trades-latest.component.scss"]
})
export class HomeTradesLatestComponent implements OnInit {
    isTradingDayLoader: ErrorWrapper<boolean>;
    tradesLoader: ErrorWrapper<Trade[]>;

    constructor(
        private readonly calendarService: CalendarService,
        private readonly tradesService: TradesService,
        private readonly errorWrapperFactory: ErrorWrapperFactory<boolean | Trade[]>) {
    }

    ngOnInit(): void {
        const today = DateTime.local();
        const todayIso = DateUtils.toApiDate(today);
        this.isTradingDayLoader = this.errorWrapperFactory.wrap(this.isWeekend(today) ? of(false) : this.calendarService.getHolidays(todayIso, todayIso).pipe(
            map(holidays => ArrayUtils.isEmpty(holidays))
        ));

        this.tradesLoader = this.errorWrapperFactory.wrap(this.isTradingDayLoader.data$.pipe(
            switchMap(isTradingDay => isTradingDay ? this.tradesService.findTradesByDate(todayIso, PAGE_SIZE) : of({items: []})),
            map(tradesList => tradesList.items)
        ));
    }

    private isWeekend(date: DateTime): boolean {
        return date.weekday === 6 || date.weekday === 7;
    }
}
