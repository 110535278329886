<div class="card">
    <div class="card-header-panel">
        <h4>Newsletter</h4>
    </div>
    <div *ngIf="newsletter$ | async as newsletter">
        <div class="card-body-plaintext" data-test="newsletter-infotext">{{newsletter.infotext}}</div>
        <div class="card-footer-link">
            <a class="link-icon-text" href="{{newsletter.link}}" target="_blank" data-test="newsletter-link">
                <span class="library-bekb-icon-font icon-link-extern white"></span>
                <span>Newsletter anmelden</span>
            </a>
        </div>
    </div>
</div>
