<ng-container *ngIf="hasNews">
    <ul class="card-body-list">
        <li *ngFor="let news of visibleNews">
            <otcx-news-entry [newsEntry]="news"></otcx-news-entry>
        </li>
    </ul>

    <div *ngIf="isExpandable" class="card-footer-expand" data-test="footer">
        <button (click)="toggleExpand()" class="btn btn-primary btn-icon-text">
            <span [ngClass]="isExpanded ? 'icon-pfeil-oben': 'icon-pfeil-unten'"
                  class="library-bekb-icon-font"></span>{{isExpanded ? "Weniger anzeigen" : showAllLabel}}
        </button>
    </div>
</ng-container>
