import {Component, OnInit} from "@angular/core";
import {AnalyticsService, AuthService} from "@kwsoft/otcx-core";

@Component({
    selector: "otcx-root",
    templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
    constructor(private readonly authService: AuthService,
                readonly analyticsService: AnalyticsService) {
        analyticsService.startTracking();
    }

    ngOnInit(): void {
        this.authService.initAuthService();
    }
}
