import {Component, OnInit, ViewEncapsulation} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {
    DateUtils,
    ErrorWrapper,
    ErrorWrapperFactory,
    NewsEntry,
    NewsService,
    SecuritiesService
} from "@kwsoft/otcx-core";

@Component({
    selector: "otcx-news",
    templateUrl: "./news.component.html",
    styleUrls: ["./news.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class NewsComponent implements OnInit {
    isin: string;
    timestamp: number;
    newsLoader: ErrorWrapper<NewsEntry>;
    securityNameLoader: ErrorWrapper<string>;
    fromApiDate = DateUtils.fromApiDate;

    static dateMatchingTimestamp(date: string, timestamp: number): boolean {
        return DateUtils.fromApiDate(date).toSeconds() === timestamp;
    }

    constructor(private readonly newsService: NewsService,
                private readonly securitiesService: SecuritiesService,
                private readonly route: ActivatedRoute,
                private readonly errorWrapperFactory: ErrorWrapperFactory<string | NewsEntry>) {
    }

    ngOnInit(): void {
        const params = this.route.snapshot.params;
        this.timestamp = +params.timestamp;
        this.isin = params.isin;
        if (!!params.isin) {
            this.securityNameLoader = this.errorWrapperFactory.wrap(this.securitiesService.getSecurity(this.isin).pipe(
                map(security => security.name)
            ));
        }
        this.newsLoader = this.errorWrapperFactory.wrap(this.fetchNewsEntryForRouteTimestamp(this.timestamp, this.isin));
    }

    private fetchNewsEntryForRouteTimestamp(timestamp: number, isin: string | null): Observable<NewsEntry> {
        return this.getNewsEntries(isin).pipe(
            map(entries => entries.find(news => NewsComponent.dateMatchingTimestamp(news.pubDate, timestamp)))
        );
    }

    private getNewsEntries(isin: string | null): Observable<NewsEntry[]> {
        return !!isin ? this.getNewsForIsin(isin) : this.getAllNews();
    }

    private getNewsForIsin(isin: string): Observable<NewsEntry[]> {
        return this.newsService.findNewsEntriesByIsin(isin);
    }

    private getAllNews(): Observable<NewsEntry[]> {
        return this.newsService.getNewsEntries().pipe(
            map(list => list.items)
        );
    }
}
