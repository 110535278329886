import {Component, HostListener, Inject, ViewEncapsulation} from "@angular/core";
import {ScrollToService} from "@urajendran-pi/ngx-scroll-to";
import {Router} from "@angular/router";
import {WINDOW} from "@kwsoft/otcx-core";

const BUTTON_DISPLAY_POINT = 300;

@Component({
    selector: "otcx-layout-scroll-to-top",
    templateUrl: "./layout-scroll-to-top.component.html",
    styleUrls: ["./layout-scroll-to-top.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class LayoutScrollToTopComponent {
    windowIsScrolled: boolean;

    constructor(@Inject(WINDOW) private readonly window: Window,
                private readonly scrollToService: ScrollToService,
                private readonly router: Router) {
    }

    @HostListener("window:scroll", [])
    onWindowScroll(): void {
        if (this.window.pageYOffset > BUTTON_DISPLAY_POINT) {
            this.windowIsScrolled = true;
        } else if (this.window.pageYOffset < 10) {
            this.windowIsScrolled = false;
        }
    }

    scrollToTop(): void {
        this.windowIsScrolled = false;

        this.scrollToService.scrollTo({offset: 0 - this.window.pageYOffset});

        if (this.currentRouteContainsFragmentPath()) {
            this.clearFragmentFromRoute();
        }
    }

    private currentRouteContainsFragmentPath(): boolean {
        return this.router.url.includes("#");
    }

    private clearFragmentFromRoute(): void {
        this.router.navigateByUrl(this.getBaseUrlWithoutFragment(), {replaceUrl: true});
    }

    private getBaseUrlWithoutFragment(): string {
        return this.router.url.substring(0, this.router.url.indexOf("#"));
    }

}
