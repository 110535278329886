import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";

import {ErrorModule, SharedModule} from "@kwsoft/otcx-core";
import {HomeSecuritiesActiveComponent} from "./home-securities-active.component";
import {HomeSecuritiesPerformersComponent} from "./home-securities-performers.component";


@NgModule({
    declarations: [
        HomeSecuritiesActiveComponent,
        HomeSecuritiesPerformersComponent
    ],
    imports: [
        SharedModule,
        RouterModule,
        ErrorModule
    ],
    exports: [
        HomeSecuritiesActiveComponent,
        HomeSecuritiesPerformersComponent
    ]
})
export class SecuritiesModule {
}
