import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {ReactiveFormsModule} from "@angular/forms";
import {ErrorModule, HomeModule as HomeCoreModule, SharedModule} from "@kwsoft/otcx-core";

import {HomePriceQuotationDocumentsComponent} from "./home-price-quotation-documents.component";
import {HomePublicationsComponent} from "./home-publications.component";
import {HomeNewsletterComponent} from "./home-newsletter.component";
import {HomeComponent} from "./home.component";
import {SecuritiesModule} from "./securities/securities.module";
import {TradesModule} from "./trades/trades.module";
import {NewsModule} from "./news/news.module";
import {IndexModule} from "./index/index.module";
import {TradingDaysModule} from "./trading-days/trading-days.module";
import {HomeRoutingModule} from "./home-routing.module";
import {HomeMarketReportsComponent} from "./home-market-reports.component";

@NgModule({
    imports: [
        SharedModule,
        SecuritiesModule,
        IndexModule,
        TradingDaysModule,
        RouterModule,
        TradesModule,
        HomeCoreModule,
        HomeRoutingModule,
        NewsModule,
        ReactiveFormsModule,
        ErrorModule
    ],
    declarations: [
        HomeComponent,
        HomePublicationsComponent,
        HomeNewsletterComponent,
        HomePriceQuotationDocumentsComponent,
        HomeMarketReportsComponent
    ]
})
export class HomeModule {
}
